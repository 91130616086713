<template>
	<div class="droptarget" v-on:drop="drop" v-on:dragover="allowDrop" style="">
		<div class="row">
			<div style="margin-top: 20px" class="col-7">Arrastré aquí las actas para determinar sus valores</div>
			<div class="col-5 text-right" v-if="items.length > 0">
				<div class="row">
					<div class="col-12 text-center">
						<b-button v-on:click="selectAll" variant="info">Seleccionar todo</b-button>
						&nbsp;
						<b-button v-on:click="getValues" variant="success">Procesar</b-button>
					</div>
				</div>
			</div>
		</div>
		<b-table :items="items" :fields="fields" striped responsive="sm" @row-clicked="openRecordDetail">
			<template #cell(status)="row">
				<b-spinner v-if="row.item.status == 'LOAD_IMAGE'" label="Cargando Imagen"></b-spinner>
				<b-badge v-if="row.item.status == 'LOADED'" pill variant="info">Cargada</b-badge>
				<b-badge v-if="row.item.status == 'WAITING_PROCESS'" pill variant="warning">Esperando</b-badge>
				<b-spinner v-if="row.item.status == 'LOADED_SERVICE'" label="Procesando imagen"></b-spinner>
				<b-badge v-if="row.item.status == 'PROCESSED'" pill variant="success">Procesada</b-badge>
				<b-badge v-if="row.item.status == 'ERROR'" pill variant="danger">Error</b-badge>
			</template>
			<template #cell(Seleccionar)="row">
				<b-form-checkbox
					v-if="row.item.status != 'PROCESSED'"
					:disabled="row.item.status != 'LOADED' && row.item.status != 'ERROR'"
					class="text-center"
					v-model="row.item.active"
					size="lg"
				></b-form-checkbox>
			</template>
		</b-table>
		<b-modal id="alertModal" hide-footer>
			<template #modal-title> <code>Error</code></template>
			<div class="d-block text-center">
				<h3>{{ errorMessage }}</h3>
			</div>
		</b-modal>
		<b-modal id="recordDetail" hide-footer>
			<template #modal-title>Detalle del acta</template>
			<b-container fluid>
				<div class="row">
					<div class="col-12 text-right">
						<b-button v-on:click="showRecord" variant="info">Ver acta</b-button>
						&nbsp;
						<b-button v-if="itemSelected.status == 'LOADED' || itemSelected.status == 'ERROR'" v-on:click="getValuesForSelected" variant="success">Procesar</b-button>
					</div>
				</div>
				<hr />
				<div class="row" v-if="itemSelected.status != 'PROCESSED' && itemSelected.status != 'LOADED_SERVICE' && itemSelected.status != 'WAITING_PROCESS'">
					<div class="col-12">
						<b-alert show variant="warning">Esta acta aun no es enviada a procesamiento.</b-alert>
					</div>
				</div>
				<div class="row" v-if="itemSelected.status == 'LOADED_SERVICE'">
					<div style="border-bottom: 1px solid grey" class="col-12 text-center">
						<b-spinner label="Procesando imagen"></b-spinner>
					</div>
				</div>
				<div class="row" v-if="itemSelected.status == 'PROCESSED'">
					<div class="col-8">Registro</div>
					<div class="col-4">Valor determinado</div>
				</div>
				<div v-if="itemSelected.status == 'PROCESSED'">
					<div style="border-bottom: 1px solid grey" class="row" v-for="(item, index) in itemSelected.valores" v-bind:key="index">
						<div class="col-8">
							<img style="width: 100%" :src="`data:image/jpeg;base64,${itemSelected.result.out_images[index]}`" />
						</div>
						<div class="col-4 align-middle">{{ item }}</div>
					</div>
				</div>
			</b-container>
		</b-modal>
	</div>
</template>

<script>
import json from '../request.json'
import axios from 'axios'
const urlService = 'https://7sylmnrx7l.execute-api.us-east-1.amazonaws.com/v1/ballotrecognition'

export default {
	components: {},
	data() {
		return {
			fields: ['Archivo', 'valores', 'status', 'Seleccionar'],
			items: [],
			loadedFiles: {},
			errorMessage: '',
			json,
			itemSelected: {}
		}
	},
	methods: {
		showRecord() {
			var image = new Image()
			image.src = `data:image/png;base64,${this.itemSelected.b64File}`
			var _window = window.open('')
			_window.document.write(image.outerHTML)
		},
		openRecordDetail(record, index) {
			console.log(record) // This will be the item data for the row
			this.itemSelected = record
			this.$bvModal.show('recordDetail')
		},
		dragStart(event) {
			console.log('dragStart')
		},
		dragging(event) {
			console.log('dragging')
			document.getElementById('demo').innerHTML = 'The p element is being dragged'
		},
		allowDrop(event) {
			console.log('allowDrop')
			event.preventDefault()
		},
		drop(event) {
			console.log('drop')
			event.preventDefault()
			console.log(this.items)
			const items = this.items

			Object.values(event.dataTransfer.files).forEach((item) => {
				console.log(item)
				if (item.type !== 'image/jpeg' && item.type !== 'image/png') {
					this.errorMessage = `El archivo ${item.name} no es una imagen jpg, solo se permiten imagenes jpg o png.`
					this.$bvModal.show('alertModal')
					return
				} else if (!this.loadedFiles[item.name]) {
					this.loadedFiles[item.name] = true
					let file = { b64File: null, Archivo: item.name, valores: 'No determinados', status: 'LOAD_IMAGE' }
					items.push(file)

					var reader = new FileReader()
					reader.readAsBinaryString(item)
					reader.onload = () => {
						file.b64File = btoa(reader.result)
						file.status = 'LOADED'
						const data = { image: file.b64File }
						this.callApi(data, file)
					}
					reader.onerror = () => {
						console.log('there are some problems')
						this.items.pop()
					}
				} else {
					this.errorMessage = `El archivo ${item.name} ya se encuentra cargado.`
					this.$bvModal.show('alertModal')
				}
			})
		},
		selectAll() {
			this.items.forEach((item) => {
				if (item.status == 'LOADED' || item.status == 'ERROR') {
					item.active = true
				}
			})
			this.$forceUpdate() // Notice we have to use a $ here
		},
		getValuesForSelected() {
			const data = { image: this.itemSelected.b64File }
			this.callApi(data, this.itemSelected)
		},
		getValues() {
			let indexesShouldSend = []
			this.items.forEach((item, index) => {
				if (item.active && (item.status == 'LOADED' || item.status == 'ERROR')) {
					indexesShouldSend.push(index)
					this.items[index].status = 'WAITING_PROCESS'
				}
			})
			// indexesShouldSend.reduce((chainPromises, index) => {
			// 	return chainPromises.then(() => {
			// 		const data = { image: this.items[index].b64File }
			// 		return this.callApi(data, this.items[index])
			// 	})
			// }, Promise.resolve())

			indexesShouldSend.forEach((index) => {
				const data = { image: this.items[index].b64File }
				return this.callApi(data, this.items[index])
			})
		},
		callApi(data, item) {
			console.log('callApi', item)
			item.status = 'LOADED_SERVICE'
			return axios({
				url: urlService,
				data,
				method: 'post',
				timeout: 30000,
				headers: {
					'Content-Type': 'application/json'
				}
			})
				.then((result) => {
					console.log('result', result)
					const body = result.data.body
					if (body.predictions && body.out_images && body.coordinates) {
						item.status = 'PROCESSED'
						item.result = body
						item.valores = body.predictions || []
						return true
					} else {
						item.status = 'ERROR'
						this.errorMessage = `No fue posible reconocer el texto escrito, verifique que la imagen enviada es un acta.`
						this.$bvModal.show('alertModal')
					}
				})
				.catch((err) => {
					console.error(err)
					item.status = 'ERROR'
					this.errorMessage = `Ocurrió un error al procesar el archivo "${item.Archivo}"`
					this.$bvModal.show('alertModal')
				})
		}
	}
}
</script>

<style scoped>
.droptarget {
	min-height: 500px;
	padding: 10px;
}
</style>
